




































































































































































































































































































































































import gql from 'graphql-tag';
import {Component, Vue} from 'vue-property-decorator';
import {BoardQuery, CreateBoardFromTemplateMutation, CreateBoardMutation} from '@/graphql/board';
import {PrivateWorkspaceTemplates, WorkspaceQuery} from '@/graphql/workspace';
import {BoardTemplates, PublicBoardTemplates} from "@/graphql/boardTemplate";
import UserAvatar from "@/components/user/UserAvatar.vue";
import {
  BoardBackground,
  PublicBoardTemplatePaginator,
  User,
  WorkspaceMember
} from "@/typescript/types";
// import {BoardBackgroundImageQuery} from "@/graphql/BoardBackground";
import ChatMessageService from "@/ChatMessageService";
import Template from "@/components/workspace/Template.vue";
import WorkspaceArchiveBoardItem from "@/components/navigation/WorkspaceArchiveBoardItem.vue";
import WorkspacePage from "@/components/layout/WorkspacePage.vue";
//@ts-ignore
import VLazyImage from "v-lazy-image/v2";

@Component({
  components: {WorkspacePage, WorkspaceArchiveBoardItem, UserAvatar, VLazyImage},
  apollo: {
    BoardTemplates: {
      query: BoardTemplates,
    },
    PublicBoardTemplates: {
      query: PublicBoardTemplates,
      variables() {
        return {
          page: this.publicBoardTemplatesPageCount
        }

      },
      result(result) {
       
        if (result && result.data && result.data.PublicBoardTemplates && this.PublicBoardTemplatesData && this.PublicBoardTemplatesData.data.length > 0) {
          this.PublicBoardTemplatesData.data  = this.filterDuplicateData([...this.PublicBoardTemplatesData.data, ...result.data.PublicBoardTemplates.data]);
          this.PublicBoardTemplatesData.paginatorInfo = result.data.PublicBoardTemplates.paginatorInfo;

        }else{
          this.PublicBoardTemplatesData = result.data.PublicBoardTemplates;
        }
      },

    },
    privateWorkspaceTemplates: {
      query: PrivateWorkspaceTemplates,
      variables() {
        return {
          id: this.workspaceId,
        };
      },
    }
    // getBackgrounds: {
    //   query: BoardBackgroundImageQuery,
    //   result(result) {
    //     this.backgroundImages = result.data.getBackgrounds;
    //   }
    // }
  },
})
export default class CreateBoard extends Vue {
  backgroundImages: BoardBackground[]  = [];
  createBoardInput: string | null = null;
  templateId: string | null = null;
  showHelp: boolean = false;
  showTemplateHelpText: boolean = false;
  descriptionInput: string | null = null;
  roomExplanationExpanded: boolean = false;
  detailedInputActive: boolean  = false;
  selectedPermission: string | null = 'workspace';
  selectedUsers: Array<User> = [];
  createInputFocus: boolean  = false;
  activeTab: number = 0;
  createdBoardName: string | null = null;
  sprintInterval = 3;

  wait: boolean  = false;
  submitError: boolean  = false;
  successLoading: boolean  = false;
  joinLoadingId: string | null = null;
  isEmojiSelectorOpen: boolean = false;
  boardBackgroundImage: string | null = null;
  chatMessageService: ChatMessageService | null = null;

  emojiPickerOpen: boolean = false;
  selectedEmoji: string | null = null;
  emojis: Array<{ icon: string, name: string }> = [
    {icon: '📅', name: 'calender'},
    {icon: '🚎', name: 'commuting'},
    {icon: '🤒', name: 'sick'},
    {icon: '🌴', name: 'vacation'},
    {icon: '🏡', name: 'working-remote'},
    {icon: '❤️️', name: 'love'},
    {icon: '💬', name: 'speech-baloon'}
  ];

  publicBoardTemplatesPageCount: number = 1;

  PublicBoardTemplatesData: PublicBoardTemplatePaginator | null = null;


  mounted() {
    document.title = "Pembio: Add new board";
    this.$gtag.event('pmb_app_mount_create_room');
    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.$refs.mainCreate.focus();
    });

    this.chatMessageService = new ChatMessageService(this.$apollo);

    if(!this.canAddNewRoom) {
      this.$buefy.toast.open({
        message: "Room limit reached",
        position: 'is-bottom-right',
        type: 'is-black',
      });
      this.$router.push({name: 'overview'})
    }


  }

  get workspaceId() {
    return this.$store.state.workspace.id;
  }

  get workspacePid() {
    return this.$store.state.active_workspace_pid;
  }

  get workspaceName() {
    return this.$store.state.workspace.name;
  }

  get myRooms() {
    return this.workspace.boards;
  }

  get currentlyOnboarding() {
    return this.$store.state.currently_onboarding;
  }

  get products(): Array<any> {
    if(this.$store.state.plans.length > 0) {
      return [...new Map(this.$store.state.plans.map((item: any) =>
        [item['stripeProduct'], item])).values()]

    }
    return [];
  }

  get canAddNewRoom() {
    if(this.$store.state.workspace.currentSubscription && this.$store.state.workspace.currentSubscription.hasAccess && this.$store.state.workspace.currentSubscription.plan.planAccess.max_board > this.myRooms.length) {

      return true;
    }else if(this.products[0].planAccess.max_board > this.myRooms.length) {

      return true
    }
    return false;
  }

  get planList() {
    return this.$store.state.plans;
  }

  get workspaceSubscription() {
    return this.$store.state.workspace.currentSubscription;
  }

  get currentPlan() {
    if(this.workspaceSubscription && !this.workspaceSubscription.hasAccess) {
      return this.planList[0];
    }else if(this.workspaceSubscription == null) {
      return this.planList[0];
    }
    return this.workspaceSubscription.plan;
  }

  get me() {
    return this.$store.state.me;
  }

  get archiveBoardsInWorkspace() {
    return this.workspace.archive_boards;
  }

  get availableBoardsInWorkspace(){
    return this.workspace.available_boards;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get board() {
    return this.$store.state.board;
  }

  get workspaceUsers() {
    let wUsers: Array<User> = [];
    this.workspace.members.forEach((element: WorkspaceMember) => {
      wUsers.push(element.user);
    });
    //return wUsers;
    return wUsers.filter((user: User) => !this.selectedUsers.find(({ id }) => user.id === id))
  }

  getCoverImage(url: string) {
    //@ts-ignore
    return this.$attachmentsLinks.file_path['boardTemplateCover']+url;
  }

  prePendEmoji(emoji: any) {
    this.selectedEmoji = emoji.data;
    this.emojiPickerOpen = false;
    //@ts-ignore
    this.$refs.mainCreate.focus();
  }

  createNew() {
    if(this.templateId) {
      this.createFromTemplate();
    } else {
      this.createBoard();
    }
  }

  addMemberToList(user: User) {
    this.selectedUsers.push(
      user
    );
  }

  togglePermission() {
    this.$gtag.event('pmb_room_create_privacy_changed');
    if (this.selectedPermission === 'workspace') {
      this.selectedPermission = 'private';
    } else if (this.selectedPermission === 'private') {
      this.selectedPermission = 'workspace';
    }
  }

  openTemplates() {
    this.$router.push({name: 'open-templates'});
    //if(this.$props.inM)
  }

  openTemplateModal(template: any) {
    this.$buefy.modal.open({
      component: Template,
      props: {
        template: template,
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'submitted': value => {
          this.$router.push({ name: 'room', params: { board_pid: value.createBoardFromTemplate.pid }});
        }
      },
      //@ts-ignore
      customClass: 'template-modal',
      parent: this,
      width: '520px',
    });
  }

  createFromTemplate() {
    this.$apollo
      .mutate({
        mutation: CreateBoardFromTemplateMutation,
        variables: {
          name: this.createBoardInput,
          workspace: this.workspaceId,
          template_id: this.templateId,
          permissions: this.selectedPermission
        },
        refetchQueries: [{query: WorkspaceQuery, variables: {pid: this.$store.state.active_workspace_pid}}],
      })
      .then(({data}) => {
        this.$router.push({ name: 'board-layout', params: {
          board_pid: data.createBoardFromTemplate.pid
        }});
        //@ts-ignore
        //this.$parent.close();
      })
    ;
  }

  createBoard() {
    if (this.wait) {
      return
    }
    this.wait = true;
    setTimeout(() => this.wait = false, 1000);
    this.successLoading = true;

    if(this.selectedEmoji) {
      this.createBoardInput = this.selectedEmoji + ' ' + this.createBoardInput;
    }

    this.$apollo
      .mutate({
        mutation: CreateBoardMutation,
        variables: {
          name: this.createBoardInput,
          workspace: this.workspaceId,
          description: this.descriptionInput,
          permissions: this.selectedPermission,
          board_level_identifier: 2,
          sprint_interval: this.sprintInterval,
          board_background_id: this.boardBackgroundImage,
        },
        refetchQueries: [{query: WorkspaceQuery, variables: {pid: this.$store.state.active_workspace_pid}}],
      })
      .then(({data: {createBoard: board}}) => {
        //this.$store.commit('set_new_board_pid', board.pid);
        this.$gtag.event('pmb_room_create_manually_success');
        this.createdBoardName = board.name;
        this.createBoardInput = null;
        if(this.selectedUsers) {
          this.addMembersToBoard(board.id);
        }
        if(this.boardBackgroundImage){
          this.chatMessageService?.sendBoardMessage(board.id, 'New background image set');
        }
        this.$nextTick(function () {
          this.$router.push({ name: 'room', params: {
            board_pid: board.pid
          }});
        });
      })
      .catch((_error) => {
      
        this.successLoading = false;
        this.submitError = true;
        setTimeout(() => {
          this.submitError = false;
          this.$nextTick(function () {
            //@ts-ignore ToDo: fix type issue
            this.$refs.mainCreate.focus();
          });
        }, 1200);
      })
      .finally(() => {
      });
  }

  joinBoard(id: string) {
    this.joinLoadingId = id;
    this.$apollo.mutate({
      mutation: gql`
                mutation JoinBoard($id: ID!) {
                    joinBoard(id: $id) {
                        id
                        pid
                        name
                    }
                }`,
      variables: {
        id: id
      },
      refetchQueries: [{query: WorkspaceQuery, variables: {pid: this.$store.state.active_workspace_pid}}],
    })
      .then((result) => {
        this.$gtag.event('pmb_user_joined_open_room_in_workspace');
        this.$router.push({name: 'board-layout', params: {board_pid: result.data.joinBoard.pid}})
      })
      .catch((_) => {
      
      })
      .finally(() => {
        setTimeout(() => {
          this.joinLoadingId = null;
        }, 1200);
      });
  }

  addMembersToBoard(id: string){
    for (let i = 0; i < this.selectedUsers.length; i++) {
      this.$apollo
        .mutate({
          mutation: gql`
                        mutation addUserToBoard($user_id: ID!, $board_id: ID!) {
                            addUserToBoard(user_id: $user_id, board_id: $board_id) {
                                user {
                                    id
                                    name
                                }
                                board {
                                    id
                                    pid
                                    name
                                }
                                role
                            }
                        }
                    `,
          variables: {
            user_id: this.selectedUsers[i].id,
            board_id: id,
          },
          refetchQueries: [{ query: BoardQuery, variables: { id: id } }],
        })
        .finally(() => {
          this.selectedUsers = [];
        });
    }
  }

  getBackgroundLink(url: string) {
    //@ts-ignore
    return this.$attachmentsLinks.file_path['background'] + url;
  }

  setBoardBackground(id: string, event: any) {
    let allClasses = document.querySelectorAll('.is-fit-cover.active');
    for (let i = 0; i < allClasses.length; i++) {
      allClasses[i].classList.remove('active')
    }
    event.target.classList.add('active');
    this.boardBackgroundImage = id;
  }

  created() {
    if(this.$store.state.autoGenUserData && this.$store.state.autoGenUserData.template_id != '') {
      this.$router.push({name: 'template-preview', params: {template_id: this.$store.state.autoGenUserData.template_id}})
    }
  }

  publicBoardTemplatesLoadMore() {
    this.publicBoardTemplatesPageCount++;
    this.$apollo.queries.PublicBoardTemplates.refetch();
  }

  filterDuplicateData(arr: Array<any>) {
    return arr.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
  }
}
