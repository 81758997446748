









import {Component, Vue, Prop} from 'vue-property-decorator';
import {Board} from '@/typescript/types';
import ConfirmGeneral from "@/components/general/ConfirmGeneral.vue";
import {WorkspaceQuery} from "@/graphql/workspace";
import store from "@/store";
import {RestoreBoardMutation} from "@/graphql/board";


@Component({
  components: {
    WorkspaceArchiveBoardItem
  }
})

export default class WorkspaceArchiveBoardItem extends Vue {
  @Prop({default: undefined, required: true})
  board!: Board;

  openConfirmArchiveBoardModal(board_id: string){
    this.$buefy.modal.open({
      component: ConfirmGeneral,
      props: {
        confirmText: 'restore this room'
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'confirmed-general': value => {
          if (value === true) {
            this.restoreBoard(board_id);
          }
        }
      },
      width: '500px',
      parent: this,
    });
  }

  restoreBoard(board_id: string) {
    this.$apollo.mutate({
      mutation: RestoreBoardMutation,
      variables: {
        id: board_id,
      },
      refetchQueries: [{query: WorkspaceQuery, variables: {pid: store.state.active_workspace_pid}}],
    }).then((res: any)=>{
      this.$gtag.event('pmb_user_restored_archived_room');
      this.$router.push({ name: 'board-layout', params: { board_pid: res.data.restoreBoard.pid } });
    }).finally(()=>{
      this.$emit('confirmed', true);
      this.$buefy.toast.open({
        message: 'Room restored!',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    })
  }

}
